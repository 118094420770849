@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('./fonts/Montserrat-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('./fonts/Montserrat-Bold.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('./fonts/Montserrat-Medium.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('./fonts/Montserrat-Light.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: url('./fonts/Montserrat-Thin.ttf') format('ttf');
  }
}

body {
  -webkit-font-smoothing: antialiased;
}

.form-label {
  @apply text-base !important;
}

strong {
  @apply font-medium;
}

.app-container {
  @apply mt-16 md:mt-[80px];
  
}

.page-container-sm {
  @apply max-w-lg mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-20;
}

.survey-container {
  @apply max-w-3xl mx-auto;
}

.section-container-sm {
  @apply max-w-lg mx-auto;
}

.page-container-md {
  @apply mx-auto max-w-md px-4 sm:max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-2xl py-10 md:py-20;
}

.page-container-lg {
  @apply mx-auto max-w-md px-4 sm:max-w-3xl px-4 sm:px-6 lg:px-8 lg:max-w-7xl py-10 md:py-20 lg:py-28;
}

/* Report Table Styles */
.text-vertical-td {
  vertical-align: middle !important;
}
.text-vertical-table {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  @apply text-lg font-medium m-1 text-center;
}
.max-col-width {
  word-wrap: break-word;
  @apply w-20 text-center;
}


/* React Dates Styles */
#mci-single-date-picker {
  @apply  block sm:text-sm outline-none rounded py-3 px-3 text-base text-white bg-brand-blue-light border-gray-200 rounded focus:border-opacity-20 placeholder-gray-600 placeholder-opacity-75 transition-all w-full; 
  
}

.DateInput_input {
  border: 1px solid white !important;
}

.DateInput_input__focused{
  all:unset;
  border: none !important;
  
}

/* NOTE: the order of these styles DO matter */
/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid red;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #d8d5f1 !important;
  color: #0a043d !important;
  border-color: #0a043d !important;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #d8d5f1 !important;
  color: white !important;
}

.DateInput_input__focused {
  border-bottom: #e6eef1 !important;
}

.CalendarMonth_caption {
  font-size: 16px;
}
.CalendarMonth_caption strong {
  font-weight: 500;
}

.prose{
  @apply text-white
}

/* Form Fileinput style */
input[type='file']::-webkit-file-upload-button,
input[type='file']::file-selector-button {
  @apply text-white bg-brand-blue hover:bg-brand-blue-light hover:text-brand-blue transition-colors font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}

input::placeholder {
  opacity: 2.5;
  color: white !important;
}

/* Footer Nav Items styling - remove first item border */
.nav-item-0 > a > div {
  padding-left: 0px !important;
}

.nav-item-0 > a > div:before {
  width: 0px !important;
}

.hide-element{
  position: absolute; 
  left: -99999px
}

.prose h2 {
  color: white;
}
